
import { Component, Vue } from 'vue-property-decorator'
import PermitAmendmentForm from '@/pages/permits/forms/amendments/PermitAmendmentForm'
import CancelWarningPrompt from '@/pages/permits/forms/CancelWarningPrompt'
import api from '@/utilities/api'

@Component({
  components: {
    'c-cancel-warning-prompt': CancelWarningPrompt,
    'c-permit-amendment-form': PermitAmendmentForm,
  },
})
export default class PermitAmendmentScreen extends Vue {
  // data // ***
  permit: Record<string, any> | null = null
  warningPromptOpen = false

  // computed // ***
  get permitId(): string {
    const params: Record<string, any> = this.$route.params
    return String(params.id)
  }
  get amendLoading() {
    const { amend: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get fetchLoading() {
    const { fetch: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get loading() {
    return this.fetchLoading || this.amendLoading
  }
  get urls() {
    const { permitId } = this
    return {
      amend: `permit/awardedPermit/${permitId}/amend`,
      fetch: `permit/awardedPermit/${permitId}`,
      view: '/permits/view',
    }
  }

  // created // ***
  created() {
    this.fetch()
  }

  // methods // ***
  fetch() {
    if (!this.loading) {
      const { fetch: url } = this.urls
      api(url, undefined, undefined)
        .then(res => (this.permit = (res || {})?.data || null))
        .catch(e => console.error(e))
    }
  }
  onCancel() {
    this.warningPromptOpen = true
  }
  onCancelConfirm() {
    this.$router.push(this.urls.view)
  }
  async onSubmit(e: Record<string, any>) {
    const { amend: url, view: redirectUrl } = this.urls
    const {
      // Driver Information // *********************************************************************
      firstName,
      lastName,
      address,
      personalEmailAddress: emailAddress,
      mobilePhoneNumber: phoneNumber,
      dvlaDriversLicenceNumber: driverLicenseNumber,
      additionalDriver: isAdditionalDriver,
      changeVehicleCostNet,
      // *******************************************************************************************

      // Vehicle Information // ********************************************************************
      hcdlNumber: hcdlBadgeNumber,
      registration: vehicleRegistrationNumber,
      make,
      model,
      colour,
      capacity,
      // vehicleType: vehicleType, // does this come down with transport api stuff?
      // dbsCertificateNumber: dbsCertificateNumber, // what is this?
      dateOfFirstRegistration: dateOfFirstRegistration,
      fuelType,
      motStatus,
      taxStatus,
      co2Emissions,
      cO2Emissions,
      plateNumber,
      isWav,
      // ghostCallSign: ghostCallSign, // What is this?
      // *******************************************************************************************

      // Supporting Documents // *******************************************************************
      hackneyCarriageDriverLicence: DriverLicence,
      hackneyCarriageDriverLicenceExpiryDate: DriverLicenceExpiry,
      hackneyCarriageVehicleLicence: VehicleLicence,
      hackneyCarriageVehicleLicenceExpiryDate: VehicleLicenceExpiry,
      hireAndRewardVehicleInsuranceCertificate: Insurance,
      hireAndRewardVehicleInsuranceCertificateExpiryDate: InsuranceExpiry,
      motCertificate: MotCertificate,
      motCertificateExpiryDate: MotCertificateExpiry,
      // *******************************************************************************************
    } = e
    const driverObject = {
      firstName,
      lastName,
      address,
      emailAddress,
      phoneNumber,
      vatNumber: null,
      hcdlBadgeNumber,
      driverLicenseNumber,
      dbsCertificateNumber: null,
      vehicleRegistrationNumber,
      make,
      model,
      colour,
      capacity,
      vehicleType: 6, // TODO : replace with value from capability when available
      dateOfFirstRegistration,
      fuelType,
      motStatus,
      taxStatus,
      // this is to manage the naming mismatch between the vehicle and ops apis
      cO2Emissions: co2Emissions || cO2Emissions,
      plateNumber,
      isAdditionalDriver,
      ghostCallSign: null,
      changeVehicleCostNet,
      isWav,
    }
    const fd = new FormData()
    fd.append('DriverModelJson', JSON.stringify(driverObject))
    fd.append('DriverLicence', DriverLicence)
    if (DriverLicenceExpiry) fd.append('DriverLicenceExpiry', DriverLicenceExpiry)
    fd.append('Insurance', Insurance)
    if (InsuranceExpiry) fd.append('InsuranceExpiry', InsuranceExpiry)
    fd.append('VehicleLicence', VehicleLicence)
    if (VehicleLicenceExpiry) fd.append('VehicleLicenceExpiry', VehicleLicenceExpiry)
    fd.append('MotCertificate', MotCertificate)
    if (MotCertificateExpiry) fd.append('MotCertificateExpiry', MotCertificateExpiry)
    api(
      url,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
      },
      { data: fd }
    )
      .then(() => this.$router.push(redirectUrl))
      .catch(e => console.error(e))
  }
}
