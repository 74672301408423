
import { Component, Prop, Vue } from 'vue-property-decorator'
import DriverInformation from '@/pages/permits/forms/amendments/steps/DriverInformation'
import ReviewAndSubmit from '@/pages/permits/forms/amendments/steps/ReviewAndSubmit'
import SupportingDocuments from '@/pages/permits/forms/amendments/steps/SupportingDocuments'
import VehicleInformation from '@/pages/permits/forms/amendments/steps/VehicleInformation'
import { motCertificateRequired } from '@/pages/permits/forms/helpers'
import { PERMIT_FORM_MODES } from '@/utilities/constants'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { getFormattedAddressFromPermit } from './helpers'
import { DateTime } from 'luxon'

@Component({
  components: {
    'c-driver-information': DriverInformation,
    'c-review-and-submit': ReviewAndSubmit,
    'c-supporting-documents': SupportingDocuments,
    'c-vehicle-information': VehicleInformation,
  },
})
export default class PermitAmendmentForm extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ required: true, type: Object })
  permit!: Record<string, any>

  // data // ***
  inReview = false
  mode: number = PERMIT_FORM_MODES.AMEND
  step = 1
  values: Record<string, any> = {}

  // computed // ***
  get addressHasChanged() {
    const { permit: p, values: v } = this
    const autoCompleteData = cloneDeep(v?.addressAutocomplete?.data)
    if (autoCompleteData) {
      const formattedAddress = getFormattedAddressFromPermit({ address: autoCompleteData })
      const formattedPermitAddress = getFormattedAddressFromPermit(p)
      return !isEqual(formattedAddress, formattedPermitAddress)
    } else return false
  }
  get hasChanges(): boolean {
    if (this.amendmentFeeFieldsHaveChanged) {
      return true
    }
    if (
      this.hackneyCarriageDriverLicenceChanged ||
      this.hackneyCarriageVehicleLicenseChanged ||
      this.hireAndRewardVehicleInsuranceCertificateChanged ||
      this.motCertificateChanged
    )
      return true
    else return false
  }
  get amendmentFeeFieldsHaveChanged(): boolean {
    if (
      this.addressHasChanged ||
      this.firstNameHasChanged ||
      this.lastNameHasChanged ||
      this.personalEmailAddressHasChanged ||
      this.registrationHasChanged ||
      this.makeHasChanged ||
      this.modelHasChanged ||
      this.colourHasChanged ||
      this.dateOfFirstRegistrationHasChanged ||
      this.availableSeatsHasChanged ||
      this.hcdlNumberHasChanged ||
      this.plateNumberHasChanged
    )
      return true
    else return false
  }
  get availableSeatsHasChanged() {
    return !isEqual(this.permit.capacity, this.values.availableSeats)
  }
  get colourHasChanged() {
    return !isEqual(this.permit.colour, this.values.colour)
  }
  get dateOfFirstRegistrationHasChanged() {
    const date = this.values.dateOfFirstRegistration
    const permitDateTime = DateTime.fromISO(this.permit.dateOfFirstRegistration)
    const permitDate = permitDateTime.toISO()
    let valueDateTime
    if (typeof date === 'string') valueDateTime = DateTime.fromISO(date)
    else if (!date) valueDateTime = DateTime.now()
    else valueDateTime = date
    const valueDate = valueDateTime.toISO()
    return !isEqual(permitDate, valueDate)
  }
  get firstNameHasChanged() {
    return !isEqual(this.permit.firstName, this.values.firstName)
  }
  get hasAmendmentFee() {
    return this.amendmentFeeFieldsHaveChanged
  }
  get hcdlNumberHasChanged() {
    return !isEqual(this.permit.hcdlBadgeNumber, this.values.hcdlNumber)
  }
  get lastNameHasChanged() {
    return !isEqual(this.permit.lastName, this.values.lastName)
  }
  get makeHasChanged() {
    return !isEqual(this.permit.make, this.values.make)
  }
  get modelHasChanged() {
    return !isEqual(this.permit.model, this.values.model)
  }
  get motCertificateRequired() {
    return motCertificateRequired(this)
  }
  get personalEmailAddressHasChanged() {
    return !isEqual(this.permit.emailAddress, this.values.personalEmailAddress)
  }
  get plateNumberHasChanged() {
    return !isEqual(this.permit.plateNumber, this.values.plateNumber)
  }
  get registrationHasChanged() {
    return !isEqual(this.permit.vehicleRegistrationNumber, this.values.registration)
  }
  get hackneyCarriageVehicleLicenseChanged() {
    if (this.values.hackneyCarriageVehicleLicence) {
      return !isEqual(
        this.permit.hackneyCarriageVehicleLicence?.name,
        this.values.hackneyCarriageVehicleLicence?.name
      )
    } else return false
  }
  get hackneyCarriageDriverLicenceChanged() {
    if (this.values.hackneyCarriageDriverLicence) {
      return !isEqual(
        this.permit.hackneyCarriageDriverLicence?.name,
        this.values.hackneyCarriageDriverLicence?.name
      )
    } else return false
  }
  get hireAndRewardVehicleInsuranceCertificateChanged() {
    if (this.values.hireAndRewardVehicleInsuranceCertificate) {
      return !isEqual(
        this.permit.hireAndRewardVehicleInsuranceCertificate?.name,
        this.values.hireAndRewardVehicleInsuranceCertificate?.name
      )
    } else return false
  }
  get motCertificateChanged() {
    if (this.values.motCertificateChanged) {
      return !isEqual(
        this.permit.motCertificateChanged?.name,
        this.values.motCertificateChanged?.name
      )
    } else return false
  }
  get steps() {
    const steps = [
      { component: 'c-driver-information', title: 'Driver Information' },
      { component: 'c-vehicle-information', title: 'Vehicle Information' },
      { component: 'c-supporting-documents', title: 'Supporting Documents' },
      { component: 'c-review-and-submit', title: 'Review and Submit' },
    ]
    return steps.map((s: Record<string, any>, i: number) => ({ ...s, step: i + 1 }))
  }

  // created // ***
  created() {
    this.resetForm()
  }

  // methods // ***
  onBack() {
    this.step--
  }
  onCancel() {
    this.$emit('cancel')
  }
  async onContinue(values: Record<string, any>) {
    const { step } = this
    this.setValues(values)
    const isLastStep = step === this.steps.length
    if (isLastStep) {
      this.submit()
    } else {
      const nextStep = step + 1
      this.step = nextStep
      const inReview = nextStep === this.steps.length
      if (inReview) this.inReview = true
    }
  }
  onSkip() {
    this.step = this.steps.length
  }
  resetForm() {
    this.step = 1
    this.resetValues()
  }
  resetValues() {
    this.setInitialValues()
  }
  setInitialValues() {
    const { permit: p = {} } = this
    this.setValues({
      ...p,
      availableSeats: p.capacity,
      personalEmailAddress: p.emailAddress,
      hcdlNumber: p.hcdlBadgeNumber,
      registration: p.vehicleRegistrationNumber,
      // map remaining values to properties with a naming mismatch
    })
  }
  setValues(values: Record<string, any> = {}) {
    const keys = Object.keys(values)
    keys.forEach((k: string) => Vue.set(this.values, k, values[k]))
  }
  onInput(e: Record<string, any> = {}) {
    this.setValues(e)
  }
  submit() {
    this.$emit('submit', this.values)
  }
  getRefName(item: Record<string, any>) {
    return 'stepperContentRef' + item.step
  }
}
