
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { vehicleInformation } from '@/pages/permits/forms/fields'
import { DateTime } from 'luxon'
import api from '@/utilities/api'
import { isRequired } from '@/utilities/validations'
import { PERMIT_FORM_MODES } from '@/utilities/constants'

const defaultValues = () => ({
  registration: '',
  // capability: 0,
  make: '',
  model: '',
  colour: '',
  dateOfFirstRegistration: DateTime.now(),
  availableSeats: 0,
  hcdlNumber: '',
  plateNumber: '',
  isWav: false,
})

@Component
export default class VehicleInformationStep extends Vue {
  // props // ***
  @Prop({ type: Number })
  mode!: number
  @Prop({ required: true, type: Object })
  permit!: Record<string, any>
  @Prop({ type: Boolean })
  showSkip!: boolean

  // data // ***
  continueAttempted = false
  transportApiCallFailed = false
  transportApiCallFinished = false
  transportApiCallLoading = false
  values: Record<string, any> = defaultValues()

  // computed // ***
  get fields(): Record<string, any> {
    return vehicleInformation()
  }
  get rules(): Record<string, any> {
    return {
      vehicleReg: [isRequired],
    }
  }
  get showTransportApiFields() {
    if (this.mode === PERMIT_FORM_MODES.AMEND) return true
    else return this.transportApiCallFinished
  }
  get transportApiCallSuccessful() {
    return this.transportApiCallFinished && !this.transportApiCallFailed
  }
  get urls() {
    return {
      fetchTransportApiData: 'vehicle/vehicle-details/' + this.values.registration,
    }
  }
  get vehicleRegErrors() {
    return this.transportApiCallFailed
      ? ['Registration not found - please check registration and try again.']
      : []
  }

  // created // ***
  created() {
    this.setInitialValues()
  }

  // watch // ***
  @Watch('permit')
  onPermitChanged() {
    this.setInitialValues()
  }

  // methods // ***
  fetchTransportApiData() {
    this.transportApiCallLoading = true
    this.transportApiCallFailed = false
    this.transportApiCallFinished = false
    const { fetchTransportApiData: url } = this.urls
    api(
      url,
      { noApiErrorHandling: true, noPrefix: true, rootUrl: this.$env.TRANSPORT_API_URL },
      undefined
    )
      .then(res => {
        this.setTransportApiFields(res?.data)
      })
      .catch(e => {
        console.error(e)
        this.transportApiCallFailed = true
      })
      .finally(() => {
        this.transportApiCallLoading = false
        this.transportApiCallFinished = true
      })
  }
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  async onContinue() {
    this.continueAttempted = true
    if (!this.showTransportApiFields) return
    const valid = await this.validate()
    if (valid) this.$emit('continue', this.values)
  }
  async onSkip() {
    const valid = await this.validate()
    if (valid) this.$emit('skip', this.values)
  }
  setInitialValues() {
    const p = this.permit
    this.values.registration = p.vehicleRegistrationNumber
    // this.values.capability = p.capability
    this.values.make = p.make
    this.values.model = p.model
    this.values.colour = p.colour
    this.values.dateOfFirstRegistration = DateTime.fromISO(p.dateOfFirstRegistration)
    this.values.availableSeats = Number(p.capacity || 0)
    this.values.hcdlNumber = p.hcdlBadgeNumber
    this.values.plateNumber = p.plateNumber
    this.values.isWav = p.isWav
  }
  resetValidation() {
    const refs: Record<string, any> = this.$refs
    refs.form.resetValidation()
  }
  resetValues() {
    Vue.set(this, 'values', defaultValues())
  }
  resetValuesExceptRegistration() {
    const reg = this.values.registration
    this.resetValues()
    this.values.registration = reg
    this.resetValidation()
  }
  setTransportApiFields(data: Record<string, any> = {}) {
    this.resetValuesExceptRegistration()
    const {
      co2Emissions,
      colour,
      fuelType,
      make,
      monthOfFirstRegistration,
      motStatus,
      taxDueDate,
      taxStatus,
      typeApproval,
      yearOfManufacture,
    } = data
    const newValues = {
      ...this.values,
      co2Emissions,
      colour,
      fuelType,
      make,
      dateOfFirstRegistration: DateTime.fromISO(monthOfFirstRegistration),
      motStatus,
      taxDueDate,
      taxStatus,
      typeApproval,
      yearOfManufacture,
    }
    Vue.set(this, 'values', newValues)
  }
  async validate(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    const valid = await refs.form.validate()
    return !!valid
  }
}
