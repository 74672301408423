
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { driverInformation } from '@/pages/permits/forms/fields'
import {
  getAddressFromPermit,
  getFormattedAddressFromPermit,
} from '@/pages/permits/forms/amendments/helpers'
import { title } from '@/utilities/filters'

const MANUAL_ADDRESS_FIELDS = Object.freeze({
  address1: '',
  address2: '',
  town: '',
  region: '',
  country: '',
  postCode: '',
})

const defaultValues = (): Record<string, any> => ({
  firstName: '',
  lastName: '',
  additionalDriver: false,
  address: { ...MANUAL_ADDRESS_FIELDS },
  addressAutocomplete: { data: {} },
  mobilePhoneNumber: '',
  personalEmailAddress: '',
  dvlaDriversLicenceNumber: '',
})

@Component
export default class DriverInformationStep extends Vue {
  // props // ***
  @Prop({ type: Number })
  mode!: number
  @Prop({ required: true, type: Object })
  permit!: Record<string, any>
  @Prop({ type: Boolean })
  showSkip!: boolean

  // data // ***
  continueAttempted = false
  showManualAddressFields = true
  values: Record<string, any> = defaultValues()

  // computed // ***
  get addressValid(): boolean {
    const address: Record<string, string> = this.values.address || {}
    const { address1, postCode } = address
    if (!address1 || !postCode) return false
    else return true
  }
  get addressAutocompleteErrorMessages(): string[] {
    return this.continueAttempted && !this.addressValid ? ['Address Required'] : []
  }
  get fields(): Record<string, any> {
    return driverInformation()
  }
  get isWhiteTicket(): boolean {
    return !!this.permit?.isWhiteTicketLocation
  }
  get loading(): boolean {
    return false
  }
  get manualAddressFields() {
    return Object.keys(MANUAL_ADDRESS_FIELDS).map(n => ({ name: n, label: title(n) }))
  }

  // created // ***
  created() {
    this.setInitialValues()
  }

  // watch // ***
  @Watch('permit')
  onPermitChanged() {
    this.setInitialValues()
  }
  @Watch('values.addressAutocomplete')
  onAddressAutocompleteChanged(v: Record<string, any> | null): void {
    this.updateManualAddressFields(v)
  }

  // methods // ***
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  async onContinue() {
    this.continueAttempted = true
    const valid = await this.validate()
    if (valid) this.$emit('continue', this.values)
  }
  async onSkip() {
    const valid = await this.validate()
    if (valid) this.$emit('skip', this.values)
  }
  setInitialValues() {
    const address = getAddressFromPermit(this.permit)
    const formattedAddress = getFormattedAddressFromPermit(this.permit)
    const p = this.permit
    this.values.firstName = p.firstName
    this.values.lastName = p.lastName
    this.values.additionalDriver = p.isAdditionalDriver
    this.values.mobilePhoneNumber = p.phoneNumber
    this.values.personalEmailAddress = p.emailAddress
    this.values.dvlaDriversLicenceNumber = p.driverLicenseNumber
    this.values.addressAutocomplete = { data: address }
    this.values.formattedAddress = formattedAddress
    this.updateManualAddressFields(address)
  }
  updateManualAddressFields(a: Record<string, any> | null): void {
    if (a?.data) Object.entries(a?.data).forEach(v => Vue.set(this.values.address, v[0], v[1]))
  }
  async validate(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    const { addressValid } = this
    const formValid = await refs.form.validate()
    return !!formValid && addressValid
  }
}
