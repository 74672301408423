
import { Component, Prop, Vue } from 'vue-property-decorator'
import StepSummaryCard from '@/pages/permits/forms/amendments/steps/StepSummaryCard.vue'
import { addId } from '@/utilities/functions'
import { getFormattedDate } from '@/utilities/org'

@Component({ components: { 'c-step-summary-card': StepSummaryCard } })
export default class ReviewAndSubmitStep extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  hasAmendmentFee!: boolean
  @Prop({ type: Boolean })
  hasChanges!: boolean
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ type: Number })
  mode!: number
  @Prop({ type: Boolean })
  motCertificateRequired!: boolean
  @Prop({ required: true, type: Object })
  permit!: Record<string, any>
  @Prop({ required: true, type: Object })
  values!: Record<string, any>

  // computed // ***
  get driverInformation(): Record<string, any>[] {
    const v: Record<string, any> = this.values || {}
    return [
      { title: 'First Name', text: v.firstName || '-' },
      { title: 'Last Name', text: v.lastName || '-' },
      { title: 'Additional Driver?', text: v.additionalDriver ? 'Yes' : 'No' },
      { title: 'Address', text: this.getAddress(v) || '-' },
      { title: 'Mobile Phone Number', text: v.mobilePhoneNumber || '-' },
      { title: 'Email', text: v.personalEmailAddress || '-' },
      { title: "DVLA Driver's License Number", text: v.dvlaDriversLicenceNumber || '-' },
    ]
  }
  get showAmendmentFeeMessage() {
    return this.hasAmendmentFee
  }
  get stepCards() {
    const { driverInformation, supportingDocuments, vehicleInformation } = this
    return addId([
      { step: 1, info: addId(driverInformation), title: 'Driver Information' },
      { step: 2, info: addId(vehicleInformation), title: 'Vehicle Information' },
      { step: 3, info: addId(supportingDocuments), title: 'Supporting Documents' },
    ])
  }
  get supportingDocuments(): Record<string, any>[] {
    const v: Record<string, any> = this.values || {}
    const sd = [
      {
        title: 'Hackney Carriage Vehicle License',
        text: v.hackneyCarriageVehicleLicence?.name || '-',
      },
      {
        title: 'HCVL ExpiryDate',
        text: v.hackneyCarriageVehicleLicence
          ? getFormattedDate(v.hackneyCarriageVehicleLicenceExpiryDate)
          : '-',
      },
      {
        title: 'Hackney Carriage Driver Licence',
        text: v.hackneyCarriageDriverLicence?.name || '-',
      },
      {
        title: 'HCDL ExpiryDate',
        text: v.hackneyCarriageDriverLicence
          ? getFormattedDate(v.hackneyCarriageDriverLicenceExpiryDate)
          : '-',
      },
      {
        title: 'Hire & Reward Vehicle Insurance Certificate',
        text: v.hireAndRewardVehicleInsuranceCertificate?.name || '-',
      },
      {
        title: 'HRVIC ExpiryDate',
        text: v.hireAndRewardVehicleInsuranceCertificate
          ? getFormattedDate(v.hireAndRewardVehicleInsuranceCertificateExpiryDate)
          : '-',
      },
    ]
    if (this.motCertificateRequired) {
      sd.push({ title: 'MOT Certificate', text: v.motCertificate?.name || '-' })
      sd.push({
        title: 'MOT Certificate Expiry Date',
        text: v.motCertificateExpiryDate ? getFormattedDate(v.motCertificateExpiryDate) : '-',
      })
    }
    return sd
  }
  get vehicleInformation(): Record<string, any>[] {
    const v: Record<string, any> = this.values || {}
    const dofr = getFormattedDate(v.dateOfFirstRegistration)
    return [
      { title: 'Registration', text: v.registration || '-' },
      // { title: 'Capability', text: v.capability || '-' },
      { title: 'Make', text: v.make || '-' },
      { title: 'Model', text: v.model || '-' },
      { title: 'Colour', text: v.colour || '-' },
      { title: 'Date of First Registration', text: dofr || '-' },
      { title: 'Available Seats', text: v.availableSeats || '-' },
      { title: 'HCDL Number', text: v.hcdlNumber || '-' },
      { title: 'Plate Number', text: v.plateNumber || '-' },
      { title: 'Is Wheelchair Accessible?', text: v.isWav ? 'Yes' : 'No'},
    ]
  }

  // methods // ***
  getAddress(a: Record<string, any> = {}): string {
    const { address1, address2, town, region, country, postCode } = a?.address || {}
    const fields = [address1, address2, town, region, country, postCode]
    const lines: string[] = []
    fields.forEach((f: string) => {
      const line = f ? f.trim() : ''
      if (!line) return
      else lines.push(line)
    })
    const address = lines.join(',\n').trim()
    if (!address) return '-'
    else return address
  }
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  onContinue() {
    this.$emit('continue')
  }
  onEdit(e: Record<string, any>) {
    this.$emit('step:input', e.step)
  }
}
