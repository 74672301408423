export const getAddressFromPermit = (permit: Record<string, any> = {}): Record<string, any> => ({
  address1: permit.address.address1,
  address2: permit.address.address2,
  country: permit.address.country,
  isoCountryCode: permit.address.isoCountryCode,
  lat: permit.address.lat,
  long: permit.address.long,
  postCode: permit.address.postCode,
  region: permit.address.region,
  source: permit.address.source,
  town: permit.address.town,
})

export const getFormattedAddressFromPermit = (permit: Record<string, any> = {}): string => {
  const address = getAddressFromPermit(permit)
  const lines = [
    address.address1,
    address.address2,
    address.town,
    address.region,
    address.postCode,
    address.country,
  ].filter(l => !!l)
  return lines.join(', ').trim() + '.'
}
