
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StepCard extends Vue {
  // props // ***
  @Prop({ type: Array })
  info!: Record<string, any>[]
  @Prop({ type: Number })
  mode!: number
  @Prop({ type: String })
  title!: string
}
