
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { supportingDocuments } from '@/pages/permits/forms/fields'
import { DateTime } from 'luxon'

const defaultValues = () => ({
  hackneyCarriageVehicleLicence: null,
  hackneyCarriageVehicleLicenceExpiryDate: DateTime.now(),
  hackneyCarriageDriverLicence: null,
  hackneyCarriageDriverLicenceExpiryDate: DateTime.now(),
  hireAndRewardVehicleInsuranceCertificate: null,
  hireAndRewardVehicleInsuranceCertificateExpiryDate: DateTime.now(),
  motCertificate: null,
  motCertificateExpiryDate: DateTime.now(),
})

@Component
export default class SupportingDocumentsStep extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  hasAmendmentFee!: boolean
  @Prop({ type: Number })
  mode!: number
  @Prop({ type: Boolean })
  motCertificateRequired!: boolean
  @Prop({ required: true, type: Object })
  permit!: Record<string, any>
  @Prop({ type: Boolean })
  showSkip!: boolean

  // data // ***
  continueAttempted = false
  values: Record<string, any> = defaultValues()

  // computed // ***
  get fields(): Record<string, any> {
    return supportingDocuments(this)
  }
  get loading(): boolean {
    return false
  }

  // created // ***
  created() {
    this.setInitialValues()
  }

  // watch // ***
  @Watch('permit')
  onPermitChanged() {
    this.setInitialValues()
  }

  // methods // ***
  onBack() {
    this.$emit('back')
  }
  onCancel() {
    this.$emit('cancel')
  }
  async onContinue() {
    this.continueAttempted = true
    const valid = await this.validate()
    if (valid) this.$emit('continue', this.values)
  }
  async onSkip() {
    const valid = await this.validate()
    if (valid) this.$emit('skip', this.values)
  }
  setInitialValues() {
    this.values = defaultValues()
  }
  async validate(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    const valid = await refs.form.validate()
    return !!valid
  }
}
